@font-face {
  font-family: "Pretendard Variable";
  src: url("../../../public/fonts/PretendardVariable.ttf") format("opentype");
  font-weight: 900;
}

@font-face {
  font-family: "Pretendard Variable";
  src: url("../../../public/fonts/PretendardVariable.ttf") format("opentype");
  font-weight: 800;
}

@font-face {
  font-family: "Pretendard Variable";
  src: url("../../../public/fonts/PretendardVariable.ttf") format("opentype");
  font-weight: 700;
}

@font-face {
  font-family: "Pretendard Variable";
  src: url("../../../public/fonts/PretendardVariable.ttf") format("opentype");
  font-weight: 600;
}

@font-face {
  font-family: "Pretendard Variable";
  src: url("../../../public/fonts/PretendardVariable.ttf") format("opentype");
  font-weight: 500;
}

@font-face {
  font-family: "Pretendard Variable";
  src: url("../../../public/fonts/PretendardVariable.ttf") format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: "Pretendard Variable";
  src: url("../../../public/fonts/PretendardVariable.ttf") format("opentype");
  font-weight: 100;
}

body {
  font-family: "Pretendard Variable" !important;
}
